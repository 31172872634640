import AvatarList from '@/common/components/AvatarList';
import CardTitle from '@/common/components/CardTitle';
import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import WorkOrderPriorityLabel from '@/components/workOrders/WorkOrderPriorityLabel';
import { useApplicationContext } from '@/context/ApplicationContext';
import type {} from '@/graphql/types';
import { formatAssetName } from '@/modules/assets/utils';
import WorkOrderStatusLabel from '@/modules/workOrders/components/WorkOrderStatusLabel';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import {} from '@/modules/workOrders/types/workOrder';
import { formatDateToMDHHmm_or_YYYYMDHHmm } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { MouseEvent, forwardRef, useMemo } from 'react';
import {} from 'react-icons/fa';
import { MdAttachment, MdDeviceHub, MdModeComment, MdMoreHoriz, MdSettings } from 'react-icons/md';
import {
  WorkOrderActionNames,
  WorkOrderActionsType,
} from '../../../../components/workOrders/WorkOrderCardListTabs';
import { WorkOrderCard_WorkOrderFragment } from './index.generated';
import DueDateDisplay from './internal/DueDateDisplay';
import IconWithCount from './internal/IconWithCount';

gql`
fragment WorkOrderCard_WorkOrder on WorkOrder {
  id
  title
  asset {
    id
    name
  }  
  status
  priority
  stoppage {
    startAt
  }
  dueDate
  assignees {
    id
    name
  }
  customFieldFileValues {
    # 本来IDを選択したいが、IWorkOrder > IWorkOrderCustomFieldFileValueのidがoptionalで型が合わないので一旦
    # TODO DisplayWorkOrder削除！！！！
    customFieldId
  }
  parts {
    quantity
  }
  commentCount
  createdAt
  createdBy {
    id
    name
  }
  countWorkOrderLinks
}
`;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type WorkOrderCardProps = {
  onWorkOrderClicked: (id: number) => void;
  // 本来不要なOptionalだけど、NotificationEvent等でDisplayWorkOrderを経由で取得しているところがあり埋められない
  // TODO DisplayWorkOrder削除！！！！
  workOrder: Omit<
    Optional<
      WorkOrderCard_WorkOrderFragment,
      | 'asset'
      | 'assignees'
      | 'commentCount'
      | 'createdBy'
      | 'customFieldFileValues'
      | 'parts'
      | 'countWorkOrderLinks'
    >,
    '__typename'
  >;
  isActive?: boolean;
  workOrderActions?: WorkOrderActionsType;
  hasManagePermission?: boolean;
};

type ActionInfoType = {
  label: string;
  message: string;
  hasPermission: boolean;
};

const WorkOrderCard = forwardRef<HTMLDivElement, WorkOrderCardProps>(
  function WorkOrderCard(props, ref?) {
    const { t } = useTranslation();
    const {
      id,
      title,
      asset,
      status,
      priority,
      stoppage,
      dueDate,
      assignees,
      customFieldFileValues,
      parts,
      commentCount,
      createdAt,
      createdBy,
      countWorkOrderLinks,
    } = props.workOrder;
    const {
      onWorkOrderClicked,
      isActive = false,
      workOrderActions = {},
      hasManagePermission = true,
    } = props;

    const { getStatusLabel } = useWorkOrderStatus();
    const { me, isAdmin } = useApplicationContext();

    const stoppageStartAt = stoppage && stoppage.startAt;
    const isDone = status === 'done';

    const actionPayload = {
      id,
      status,
    };

    const _workOrderActions = { ...workOrderActions };

    const workOrderActionsKeys = Object.keys(_workOrderActions) as WorkOrderActionNames[];

    const handleActionClick = (event: MouseEvent<HTMLButtonElement>, _action: string) => {
      event.stopPropagation();

      const action = workOrderActions && workOrderActions[_action as WorkOrderActionNames];
      action && action(actionPayload);
    };

    const isUserWorkOrderOwner = createdBy?.id === me?.id;
    const hasChangeStatusPermission = isAdmin || !isDone;
    const hasDeletePermission = isAdmin || (!isDone && isUserWorkOrderOwner);

    const workOrderActionInfoMap: Record<WorkOrderActionNames, ActionInfoType> = useMemo(() => {
      return {
        changeStatusDone: {
          label: getStatusLabel('done'),
          message: t('warning.no-permission.complete'),
          hasPermission: hasChangeStatusPermission,
        },
        onDelete: {
          label: t('actions.delete'),
          message: t('warning.no-permission.delete'),
          hasPermission: hasDeletePermission,
        },
        removeWorkOrderLink: {
          label: t('actions.remove-task-link'),
          message: '',
          hasPermission: true, // 誰でもリンクを削除できる
        },
      };
    }, [getStatusLabel, hasChangeStatusPermission, hasDeletePermission, t]);

    const checkPermissionByAction = (action: WorkOrderActionNames) => {
      return hasManagePermission && workOrderActionInfoMap[action].hasPermission;
    };

    return (
      <Flex
        py={2}
        px={3}
        _hover={{
          bg: isActive ? 'primary.50' : 'neutral.50',
          cursor: 'pointer',
        }}
        bg={isActive ? 'primary.50' : 'transparent'}
        borderWidth={1}
        onClick={() => {
          onWorkOrderClicked(id);
        }}
        ref={ref}
        direction='column'
        justifyContent='space-between'
        gap={1}
      >
        <Flex justifyContent='space-between' gap={1}>
          <Flex direction='row' alignItems='center' gap='1' overflow='hidden'>
            <CardTitle title={title ?? ''} />
            <WorkOrderStatusLabel status={status} />
          </Flex>
          {workOrderActionsKeys.length > 0 && (
            <Menu>
              <MenuButton
                as={IconButton}
                size='sm'
                variant='ghost'
                _hover={{ bg: 'white' }}
                rounded='full'
                aria-label='WorkOrder Quick Action'
                icon={<MdMoreHoriz />}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList minW='150px'>
                {workOrderActionsKeys.map((action) => {
                  return (
                    <PopoverMessageWrapper
                      message={workOrderActionInfoMap[action].message}
                      isDisabled={!checkPermissionByAction(action)}
                      key={action}
                    >
                      <MenuItem
                        onClick={(e) => handleActionClick(e, action)}
                        fontSize='sm'
                        fontWeight='normal'
                        isDisabled={!checkPermissionByAction(action)}
                      >
                        {workOrderActionInfoMap[action].label}
                      </MenuItem>
                    </PopoverMessageWrapper>
                  );
                })}
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Flex direction='column'>
          {asset && (
            <Text fontSize='sm' lineHeight='5'>
              {formatAssetName(asset)}
            </Text>
          )}

          <Flex my={1} justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center' gap={3}>
              {customFieldFileValues !== undefined && (
                <IconWithCount icon={MdAttachment} count={customFieldFileValues.length} />
              )}

              {parts !== undefined && <IconWithCount icon={MdSettings} count={parts.length} />}
              {commentCount !== undefined && commentCount > 0 && (
                <IconWithCount icon={MdModeComment} count={commentCount} />
              )}
              {countWorkOrderLinks !== undefined && (
                <IconWithCount icon={MdDeviceHub} count={countWorkOrderLinks} />
              )}
            </Flex>
            {assignees && assignees.length > 0 && (
              <AvatarList users={assignees} iconThreshold={3} />
            )}
          </Flex>

          <Flex justifyContent='space-between' alignItems='center'>
            <Flex direction='row' gap={1}>
              <WorkOrderCardDateDisplay
                stoppageStartAt={stoppageStartAt}
                createdAt={createdAt}
                createdByName={createdBy?.name}
              />
            </Flex>
            <Flex direction='row' gap={1}>
              {dueDate && <DueDateDisplay dueDate={dueDate} isDone={isDone} />}
              {priority && <WorkOrderPriorityLabel priority={priority} />}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

const WorkOrderCardDateDisplay = ({
  stoppageStartAt,
  createdAt,
  createdByName,
}: { stoppageStartAt?: string; createdAt: string; createdByName?: string }) => {
  const { t } = useTranslation();

  if (stoppageStartAt) {
    return (
      <Text color='text.error' fontSize='xs' lineHeight='4'>
        {formatDateToMDHHmm_or_YYYYMDHHmm(stoppageStartAt)}&nbsp;{t('date.stoppage-start')}
      </Text>
    );
  }
  return (
    <Text color='text.secondary' fontSize='xs' lineHeight='4'>
      {formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}&nbsp;
      {createdByName ? t('created-by', { name: createdByName }) : t('create')}
    </Text>
  );
};

export default WorkOrderCard;
